/*
=========================================================
* Xelorian React - v2.1.0
=========================================================

* Product Page: https://www.xelorian.com/product/material-kit-react
* Copyright 2023Xelorian (https://www.xelorian.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Xelorian React components
import MKBox from "components/MKBox";

// Xelorian React examples
import RotatingCard from "examples/Cards/RotatingCard";
import RotatingCardFront from "examples/Cards/RotatingCard/RotatingCardFront";
import RotatingCardBack from "examples/Cards/RotatingCard/RotatingCardBack";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

// Images
import bgFront from "assets/images/rotating-card-bg-front.jpeg";
import bgBack from "assets/images/rotating-card-bg-back.jpeg";

function Information() {
  return (
    <MKBox component="section" py={6} my={6}>
      <Container>
        <Grid container item xs={11} spacing={3} alignItems="center" sx={{ mx: "auto" }}>
          <Grid item xs={12} lg={4} sx={{ mx: "auto" }}>
            <RotatingCard>
              <RotatingCardFront
                image={bgFront}
                icon="touch_app"
                title={
                  <>
                    Delivering expert consulting in IT, digital projects, and strategic management to help your business succeed
                    
                  </>
                }
                description="We Help to Grow Your Business"
              />
              <RotatingCardBack
                image={bgBack}
                title="Discover More"
                description="We Help to Grow Your Business"
                action={{
                  type: "internal",
                  route: "/contact",
                  label: "Contact Us To Know more",
                }}
              />
            </RotatingCard>
          </Grid>
          <Grid item xs={12} lg={7} sx={{ ml: "auto" }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="content_copy"
                  title="Tailored Project Solutions"
                  description="Every project is managed with a consulting lens, ensuring that the strategies and methodologies align with your business goals and industry requirements."
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="flip_to_front"
                  title="Strategic Product Management"
                  description="Xelorian offers deep strategic insights to help businesses navigate digital transformation. We develop strategies that align technology with business goals for long-term success and competitive advantage."
                />
              </Grid>
            </Grid>
            <Grid container spacing={3} sx={{ mt: { xs: 0, md: 6 } }}>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="price_change"
                  title="Comprehensive Strategy Consulting"
                  description="Xelorian offers deep strategic insights to help businesses navigate digital transformation. We develop strategies that align technology with business goals for long-term success and competitive advantage."
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="devices"
                  title="Fully Embedded Security Soutions"
                  description="Regardless of the platform, the solutions will naturally fit the given enviornment."
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
